<template>
  <div>
<!--    <div class="block-title">-->
<!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>价格配置</div>-->
<!--    </div>-->
    <el-form label-position="left" class="common-form left_10" label-width="110px" style="padding-top: 10px"
             :model="form" :rules="rules" ref="form">
      <el-form-item class="not_required" label="商品：" prop="good">
       {{form.goodsType===0?"陪护床":form.goodsType===1?"轮椅":"-"}}
      </el-form-item>
      <el-form-item label="押金金额：" prop="depositAmount">
        <el-input class="small-input" type="number" v-model="form.depositAmount" placeholder="请输入押金金额"></el-input><span class="left_5">元</span>
      </el-form-item>
      <el-form-item class="not_required" label="日间：" prop="free">
        {{ form.leisureStart }} - {{ form.leisureEnd }}
      </el-form-item>
      <el-form-item class="not_required" label="夜间：" prop="free">
        {{ form.busyStart }} - {{ form.busyEnd }}
      </el-form-item>
      <el-form-item label="日间价：" prop="leisurePrice">
        <el-input class="small-input" type="number" v-model="form.leisurePrice" placeholder="请输入闲时价"></el-input>
        <span class="left_5">元/小时</span>
      </el-form-item>
      <el-form-item label="日间封顶价：" prop="leisureCeilingPrice">
        <el-input class="small-input" type="number" v-model="form.leisureCeilingPrice" placeholder="请输入闲时封顶价"></el-input><span class="left_5">元</span>
      </el-form-item>
      <el-form-item label="夜间价：" prop="busyPrice">
        <el-input class="small-input" type="number" v-model="form.busyPrice" placeholder="请输入忙时价"></el-input>
        <span class="left_5">元/小时</span>
      </el-form-item>
      <el-form-item label="夜间封顶价：" prop="busyCeilingPrice">
        <el-input class="small-input" type="number" v-model="form.busyCeilingPrice" placeholder="请输入忙时封顶价"></el-input><span class="left_5">元</span>
      </el-form-item>
      <el-form-item label="免费时长：" prop="busyCeilingPrice">
        <el-input class="small-input" type="number" v-model="form.freeTime" placeholder="请输入免费时长"></el-input><span class="left_5">分钟</span>
      </el-form-item>
    </el-form>
    <div class="btn-list">
      <el-button style="padding: 12px 50px" type="primary" :loading="loading" @click="save">保 存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceDetail",
  computed:{
    departmentId(){
      return this.$route.query.id
    }
  },
  data(){
    const validateTime = (rule, value, callback) => {
      if (/^[0-9]+.?[0-9]*$/.test(value)) {
        callback();
      } else {
        callback(new Error("价格必须为正数"))
      }
    }
    return {
      form:{
        depositAmount:'',
        leisurePrice:'',
        leisureCeilingPrice:'',
        busyPrice:'',
        busyCeilingPrice:'',
        freeTime:''
      },
      rules:{
        depositAmount:[
          {required: true, message: '请输入押金金额', trigger: 'blur'},
          {validator: validateTime, trigger: 'blur'}
        ],
        leisurePrice:[
          {required: true, message: '请输入闲时价', trigger: 'blur'},
          {validator: validateTime, trigger: 'blur'}
        ],
        leisureCeilingPrice:[
          {required: true, message: '请输入闲时封顶价', trigger: 'blur'},
          {validator: validateTime, trigger: 'blur'}
        ],
        busyPrice:[
          {required: true, message: '请输入忙时价', trigger: 'blur'},
          {validator: validateTime, trigger: 'blur'}
        ],
        busyCeilingPrice:[
          {required: true, message: '请输入忙时封顶价', trigger: 'blur'},
          {validator: validateTime, trigger: 'blur'}
        ]
      },
      loading:false
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getPriceConfig(){
      this.$axios.get("/sys-api/site-api/getPrice",{id:this.departmentId},(res) => {
        if(res.code === '100'){
          this.form = res.data || {};
        }
      })
    },
    save(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = {
            id:this.departmentId,
            depositAmount:this.form.depositAmount,
            leisurePrice:this.form.leisurePrice,
            leisureCeilingPrice:this.form.leisureCeilingPrice,
            busyPrice:this.form.busyPrice,
            busyCeilingPrice:this.form.busyCeilingPrice,
            freeTime: this.form.freeTime
          }
          this.$axios.post("/sys-api/site-api/setPrice",formData,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("单位价格配置成功");
              this.getPriceConfig();
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getPriceConfig();
  }
}
</script>

<style scoped lang="scss">
.small-input {
  width: 150px;
}
.btn-list{
  margin-left: 100px;
  margin-top: 20px;
}
::v-deep input.el-input__inner::-webkit-outer-spin-button,
::v-deep input.el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input.el-input__inner[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
